<script>
import moment from 'moment';
moment.locale('pt-BR');
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import SaleService from '@/services/sale'
import socket from '@/sockets/client';

export default {
  page: {
    title: "Pedido",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Pedido",
      userRole: 'store',
      saleId: '',
      sale: {
        user: {},
        deliveryAddress: {},
        delivery: {},
      },

      fields: [
        { key: 'code', sortable: false, label: '#' },
        { key: 'userName', sortable: true, label: 'Cliente' },
        { key: 'date', sortable: true, label: 'Data/Hora', formatter: (value) => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss');
        } },
        { key: 'statusDescription', sortable: true, label: 'Status' },
        { key: 'paymentDescription', sortable: true, label: 'Pagamento' },
        { key: 'deliveryDescription', sortable: true, label: 'Entrega' },
        { key: 'totalValue', sortable: true, label: 'Valor do Pedido', formatter: (value) => {
          return (value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } },
        { key: 'actions', label: 'Ações' },
      ],
    };
  },
  mounted() {
    this.saleId = this.$route.params.id;

    this.load();

    this.$store.dispatch('USER_DATA').then((res) => {
      this.userRole = res.role;
    })

    socket.on('updateSale', (data) => {
      if (this.sale._id === data) {
        this.load(false);
      }
    })
  },
  methods: {
    load(showLoading = true) {
      let loader;
      if (showLoading) {
        loader = this.$loading.show();
      }

      SaleService.getStoreSale(this.saleId).then(res => {
        if (loader) {
          loader.hide();
        }

        this.sale = res.data;
        this.title = `Pedido #${this.sale.code}`
      }).catch(() => {
        if (loader) {
          loader.hide();
        }
      })
    },
    formatDate(value) {
      if (value) {
        return moment(value).format('DD/MM/YYYY HH:mm:ss');
      }

      return '-';
    },
    cancelSale() {
      this.$bvModal.hide('modal-cancel');

      let loader = this.$loading.show();
      SaleService.changeSaleStatus(this.saleId, 'canceled').then(res => {
        loader.hide();

        this.$toast.open('Pedido cancelado com sucesso');

        this.sale = res.data.sale;
      }).catch(() => {
        loader.hide();
      })
    },
    sentSale() {
      this.$bvModal.hide('modal-sent');

      let loader = this.$loading.show();
      SaleService.changeSaleStatus(this.saleId, 'sent').then(res => {
        loader.hide();

        this.$toast.open('Status do pedido atualizado com sucesso');

        this.sale = res.data.sale;
      }).catch(() => {
        loader.hide();
      })
    },
    confirmSale() {
      let loader = this.$loading.show();
      SaleService.changeSaleStatus(this.saleId, 'confirmed').then(res => {
        loader.hide();

        this.$toast.open('Pedido confirmado com sucesso');

        this.sale = res.data.sale;
      }).catch(() => {
        loader.hide();
      })
    },
    printTag() {
      SaleService.printDeliveryTag(this.sale._id).then(res => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `etiqueta-${this.sale.code}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        this.$toast.error('Não foi possível fazer o download da etiqueta');
      })
    },
    callDelivery() {
      this.$bvModal.hide('modal-delivery');

      let loader = this.$loading.show();
      SaleService.requestDelivery(this.saleId).then(res => {
        loader.hide();

        Swal.fire("Entregador Solicitado!", "Aguarde até que os produtos sejam retirados na sua loja", "success");

        this.sale = res.data.sale;
      }).catch((err) => {
        loader.hide();

        const errorData = err.response.data;
        if (errorData.code) {
          if (errorData.code === 'E9999') {
            return this.$toast.warning(errorData.msg);
          }
        }

        this.$toast.error('Ocorreu um erro ao solicitar o entregador');
      })
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title mb-4">Status</h4> -->
            <div class="hori-timeline" :class="{ 'small': sale.confirmDate }">
              <ul class="list-inline events">
                <li class="list-inline-item event-list">
                  <div class="mt-2" :class="{ 'inactive': !sale.saleDate }">
                    <div class="event-up-icon">
                      <i class="bx bx-cart h1 text-success"></i>
                    </div>
                    <div class="event-date mt-2">
                      <h5 class="mt-2">Pedido Realizado</h5>
                      <div class="text-muted">{{formatDate(sale.saleDate)}}</div>
                    </div>
                  </div>
                </li>
                <li class="list-inline-item event-list" v-if="sale.paymentDate || sale.status !== 'canceled'">
                  <div class="mt-2" :class="{ 'inactive': !sale.paymentDate }">
                    <div class="event-up-icon">
                      <i
                        class="bx bx bx-credit-card h1 text-success"
                      ></i>
                    </div>
                    <div class="event-date mt-2">
                      <h5 class="mt-2">Pagamento Confirmado</h5>
                      <div class="text-muted">{{formatDate(sale.paymentDate)}}</div>
                    </div>
                  </div>
                </li>

                <li class="list-inline-item event-list" v-if="sale.confirmDate">
                  <div class="mt-2" :class="{ 'inactive': !sale.confirmDate }">
                    <div class="event-up-icon">
                      <i
                        class="bx bx bxs-truck h1 text-success"
                      ></i>
                    </div>
                    <div class="event-date mt-2">
                      <h5 class="mt-2">Pedido Confirmado</h5>
                      <div class="text-muted">{{formatDate(sale.confirmDate)}}</div>
                    </div>
                  </div>
                </li>

                <li class="list-inline-item event-list" v-if="sale.sentDate || sale.status !== 'canceled'">
                  <div class="mt-2" :class="{ 'inactive': !sale.sentDate }">
                    <div class="event-up-icon">
                      <i
                        class="bx bx bxs-truck h1 text-success"
                      ></i>
                    </div>
                    <div class="event-date mt-2">
                      <h5 class="mt-2">Pedido Despachado</h5>
                      <div class="text-muted">{{formatDate(sale.sentDate)}}</div>
                    </div>
                  </div>
                </li>

                <li class="list-inline-item event-list" v-if="sale.status !== 'canceled'">
                  <div class="mt-2" :class="{ 'inactive': !sale.doneDate }">
                    <div class="event-up-icon">
                      <i
                        class="bx bx bx-check-circle h1 text-success"
                      ></i>
                    </div>
                    <div class="event-date mt-2">
                      <h5 class="mt-2">Pedido Finalizado</h5>
                      <div class="text-muted">{{formatDate(sale.doneDate)}}</div>
                    </div>
                  </div>
                </li>

                <li class="list-inline-item event-list"  v-if="sale.status === 'canceled'">
                  <div class="mt-2" :class="{ 'inactive': !sale.canceledDate }">
                    <div class="event-up-icon">
                      <i
                        class="bx bx bx-check-circle h1 text-danger"
                      ></i>
                    </div>
                    <div class="event-date mt-2">
                      <h5 class="mt-2 text-danger">Pedido Cancelado</h5>
                      <div class="text-danger">{{formatDate(sale.canceledDate)}}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Opções</h4>

            <div class="button-items">
              <b-button size="lg" block variant="success" @click="$bvModal.show('modal-sent')" :disabled="sale.status !== 'paymentAccept'">Alterar Status p/ DESPACHADO</b-button>
              <b-button size="sm" block variant="outline-danger" @click="$bvModal.show('modal-cancel')">Cancelar Pedido</b-button>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="row">
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered mb-0 table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>Produto</th>
                    <th>Descrição</th>
                    <th>Preço</th>
                    <th>Qtde.</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in sale.items" :key="data._id">
                    <td>
                      <img
                        v-bind:src="data.productStore.product.images[0]"
                        alt="product-img"
                        title="product-img"
                        class="avatar-md"
                      />
                    </td>
                    <td>
                      <h5 class="font-size-14 text-truncate">
                        {{data.productStore.product.description}}
                      </h5>
                      <p class="mb-0">
                        {{data.productVariation}}
                      </p>
                    </td>
                    <td>{{(data.price || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</td>
                    <td>{{data.quantity}}</td>
                    <td>{{((data.quantity * data.price) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-6">
            <router-link
              tag="a"
              to="/store/sales"
              class="btn text-muted d-none d-sm-inline-block btn-link"
            >
              <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de pedidos
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card" v-if="userRole === 'store' && (sale.status === 'paymentAccept' || sale.status === 'sent' || sale.status === 'confirmed')">
          <div class="card-body">
            <h4 class="card-title mb-3">Opções</h4>

            <div class="button-items">
              <b-button block variant="success" @click="confirmSale" v-if="sale.status === 'paymentAccept'">Confirmar Pedido</b-button>
              <b-button block variant="success" @click="$bvModal.show('modal-sent')" v-if="sale.status === 'confirmed'">Alterar Status p/ DESPACHADO</b-button>
              <b-button block variant="outline-success" @click="printTag">Imprimir Etiqueta</b-button>
              <b-button block variant="outline-danger" @click="$bvModal.show('modal-cancel')">Cancelar Pedido</b-button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Dados do Cliente</h4>

            <div class="table-responsive mb-0">
              <table class="table mb-0">
                <tbody>
                  <tr>
                    <td>Nome:</td>
                    <td>{{sale.user.name}}</td>
                  </tr>

                  <tr>
                    <td>CPF:</td>
                    <td>{{sale.user.cpf}}</td>
                  </tr>

                  <tr>
                    <td>Telefone:</td>
                    <td>{{sale.user.phoneNumber}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Dados de Entrega</h4>

            <div class="table-responsive mb-0">
              <table class="table mb-0">
                <tbody>
                  <tr>
                    <td>Endereço:</td>
                    <td>{{sale.deliveryAddress.street}}, {{sale.deliveryAddress.number}} {{sale.deliveryAddress.complement ? ' - ' : ''}} {{sale.deliveryAddress.complement || ''}}</td>
                  </tr>

                  <tr>
                    <td>Bairro:</td>
                    <td>{{sale.deliveryAddress.district}}</td>
                  </tr>

                  <tr>
                    <td>Cidade:</td>
                    <td>{{sale.deliveryAddress.city}}/{{sale.deliveryAddress.state}}</td>
                  </tr>

                  <tr>
                    <td>CEP:</td>
                    <td>{{sale.deliveryAddress.zipcode}}</td>
                  </tr>

                  <tr v-if="sale.deliveryStatus">
                    <td>Status da Entrega:</td>
                    <td>{{sale.deliveryStatus}}</td>
                  </tr>

                  <tr v-if="sale.delivery.integrationCourierName">
                    <td>Entregador:</td>
                    <td>{{sale.delivery.integrationCourierName}}</td>
                  </tr>

                  <tr v-if="sale.delivery.integrationTrackingUrl">
                    <td>URL de rastreio:</td>
                    <td><a :href="sale.delivery.integrationTrackingUrl" target="_blank">Clique para visualizar</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->

            <div class="button-items mt-2" v-if="!sale.deliveryDriver && !sale.delivery.integrationOrderId && userRole === 'store' && sale.status !== 'canceled' && sale.status !== 'done'">
              <b-button block variant="success" @click="$bvModal.show('modal-delivery')">SOLICITAR ENTREGADOR</b-button>
            </div>

             <div class="button-items mt-2" v-if="sale.deliveryDriver && userRole === 'admin'">
              <b-button block variant="outline-success" @click="$bvModal.show('modal-delivery-data')">Visualizar Dados da Entrega</b-button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Total do Pedido</h4>

            <div class="table-responsive">
              <table class="table mb-0">
                <tbody>
                  <tr>
                    <td>Produtos :</td>
                    <td>{{(sale.totalProducts || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</td>
                  </tr>
                  <tr>
                    <td>Taxa de Entrega :</td>
                    <td>{{(sale.totalDelivery || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</td>
                  </tr>
                  <tr v-if="sale.totalDiscount">
                    <td>Desconto :</td>
                    <td>
                      - {{(sale.totalDiscount || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}
                      <div v-if="sale.coupon">
                        <span class="badge badge-pill badge-soft-success font-size-12 mt-1">{{sale.coupon.code}}</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="font-size-18">
                    <th>Total :</th>
                    <th>{{(sale.totalValue || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>

    <b-modal
      id="modal-cancel"
      title="Cancelar Pedido?"
      title-class="font-18"
      centered
    >
      <p>Tem certeza que deseja cancelar o pedido?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-cancel')" class="mr-2">Não</b-btn>
        <b-btn variant="primary" @click="cancelSale">Sim, cancelar agora</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-sent"
      title="Despachar Pedido?"
      title-class="font-18"
      centered
    >
      <p>Tem certeza que deseja alterar o status do pedido?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-sent')" class="mr-2">Voltar</b-btn>
        <b-btn variant="primary" @click="sentSale">Confirmar</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-delivery"
      title="Solicitar Entregador?"
      title-class="font-18"
      centered
    >
      <p>Será enviada uma solicitação para retirada dos produtos aos entregadores mais próximos. Deseja continuar?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-delivery')" class="mr-2">Voltar</b-btn>
        <b-btn variant="primary" @click="callDelivery">Confirmar</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-delivery-data"
      size="lg"
      scrollable
      title="Dados da Entrega"
      title-class="font-18"
      hide-footer
    >
      <div class="register-images" v-if="sale.deliveryDriver">
        <p class="font-weight-bold mb-2">Dados da Entrega</p>
        <p class="mb-0">NOME: {{sale.deliveryDriver.confirmationName}}</p>
        <p>RG: {{sale.deliveryDriver.confirmationDocument}}</p>

        <p class="font-weight-bold">Imagem da Entrega</p>
        <img :src="sale.deliveryDriver.receipt" />

        <p class="font-weight-bold">Assinatura</p>
        <img :src="sale.deliveryDriver.confirmationSignature" />
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .hori-timeline .events .event-list .event-up-icon .bx {
    position: relative;

    ::before {
      border: none;
    }
  }

  .hori-timeline.small .events .event-list {
    width: 20%;
  }

  .inactive {
    .text-primary, .text-success, h5, .text-muted {
      color: #dedede !important;
    }
  }

  .register-images {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
      height: 200px;
      object-fit: contain;
      margin-bottom: 20px;
    }
  }
</style>