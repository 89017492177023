import api from '@/services/api'

export default {
  getStoreSales(page, user) {
    let url = '/sales/store?page=' + page;

    if (user) {
      url += `&user=${user}`;
    }

    return api.get(url)
  },

  getStoreSale(id) {
    return api.get(`/sales/store/${id}`)
  },

  changeSaleStatus(id, status) {
    return api.post(`/sales/store/change-status/${id}`, { status })
  },

  printDeliveryTag(id) {
    return api({
      url: `/sales/print-tag/${id}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  requestDelivery(saleId) {
    return api.post(`/deliveries/request/${saleId}`)
  },
}